<template>
  <div
    class="festivals-item"
    :id="id"
  >
    <div :class="{'festivals-item__disabled': returnDataStatus}" class="festivals-item__box festivals-item__box--mob-hide">
      <div v-if="date" class="festivals-item__text festivals-item__text--date">
        {{ time }}, <span>{{ dateDay }} {{ dateMonth.slice(0, -1) }}</span>
      </div>
      <div v-else class="festivals-item__text festivals-item__text--date">
        {{ time }}, <span>{{ day }} {{ month }}</span>
      </div>
      <div
        class="festivals-item__text festivals-item__text--small festivals-item__text--lh festivals-item__text--gathering"
        v-if="gathering"
      >
        Сбор гостей с {{ gathering }}
      </div>
    </div>
    <div :class="{'festivals-item__disabled': returnDataStatus}" class="festivals-item__box festivals-item__box--flex">
      <div class="festivals-item__box-inner festivals-item__box-inner--mr">
        <div class="festivals-item__text festivals-item__text--inline">
          {{ title }}
          <div class="hint festivals-item__hint">
            <div class="hint__circle"></div>
            <div class="hint__item">
              <b class="hint__title">{{ hint_overhead }}</b>
              {{ hint }}
            </div>
          </div>
        </div>
        <div class="festivals-item__leading-info">

          <div
            class="festivals-item__text festivals-item__text--small festivals-item__text--leading"
            v-if="description"
          >
            {{ description }}
          </div>
        </div>
      </div>
      <div class="festivals-item__box festivals-item__box--mob-show">
        <div v-if="date" class="festivals-item__text festivals-item__text--date">
        {{ time }}, <span>{{ dateDay }} {{ dateMonth.slice(0, -1) }}</span>
        </div>
        <div v-else class="festivals-item__text festivals-item__text--date">
          {{ time }}, <span>{{ day }} {{ month }}</span>
        </div>
        <!-- <div class="festivals-item__text festivals-item__text--date">
          {{ time }}, <span>{{ day }} {{ month }}</span>
        </div> -->
        <div
          class="festivals-item__text festivals-item__text--small festivals-item__text--lh festivals-item__text--gathering"
          v-if="gathering"
        >
          Сбор гостей с {{ gathering }}
        </div>
      </div>
      <div class="festivals-item__box-inner festivals-item__box-inner--width festivals-item__box-inner--online">
        <div class="festivals-item__text festivals-item__text--mob">
          {{ place }}
        </div>
        <div class="festivals-item__info">
          <div v-if="online == 1" class="festivals-item__online">
            Online
          </div>
          <div class="festivals-item__metros">
            <div
              class="festivals-item__text festivals-item__text--small festivals-item__address"
              v-for="metro in metros"
              :key="metro.id"
            >
              <div
                class="festivals-item__circle"
                :style="{ background: `${metro.branch}` }"
              ></div>
              {{ metro.name }}
            </div>
          </div>

          <button
            @click="coordinates"
            class="festivals-item__text festivals-item__text--small festivals-item__map"
            v-if="metros.length > 0"
          >
            <svg-icon class="festivals-item__icon" name="map"/>
            <span class="festivals-item__map-text">На карте</span>
          </button>
        </div>

        <div
          class="festivals-item__text festivals-item__text--small festivals-item__text--online"
          v-if="online == 1"
        >
          Смотрите в формате онлайн-трансляции
        </div>
      </div>
    </div>

    <a
      :target="link_other_resource.length ? '_blank' : ''"
      :href="href"
      class="button festivals-item__btn"
      :class="{
          disabled: returnDataStatus || no_tickets == 1 || sold_out === true,
          'twf' : targetTimepad.length,
          'abiframelnk' : inticketsLink.length
        }"
      :data-twf-target-state="targetTimepad"
    >
      <p>{{ returnDataStatus ? 'Событие состоялось' : btn_name }}</p>
      <div class="festivals-item__bucket" v-if="!returnDataStatus && bucket == 1">
        <span>+</span>
        <svg-icon name="bill" class="festivals-item__bill"/>
      </div>
      <svg-icon v-if="!returnDataStatus" name="arrow" class="button__arrow"></svg-icon>
    </a>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "FestivalsItem",
  components: {SvgIcon},
  props: {
    date: {
      type: String,
    },
    time: {
      type: String
    },
    gathering: {
      type: String
    },
    day: {
      type: Number
    },
    month: {
      type: String
    },
    title: {
      type: String
    },
    place: {
      type: String
    },
    description: {
      type: String
    },
    metros: {
      type: Array,
      default: () => []
    },
    price: {
      type: Number
    },
    hint: {
      type: String
    },
    no_tickets: {
      type: String,
    },
    sold_out: {
      type: Boolean,
    },
    cost: {
      type: Object,
      default: () => {
      }
    },
    btn_name: {
      type: String,
    },
    bucket: {
      type: String
    },
    id: {
      type: String,
    },
    targetTimepad: {
      type: String
    },
    href: {
      type: String,
    },
    online: {
      type: String,
    },
    link_other_resource: {
      type: String,
    },
    hint_overhead: {
      type: String
    },
    inticketsLink: {
      type: String,
    }
  },
  data() {
    return {
      dateNow: null,
      dateDay: null,
      dateMonth: null,
    }
  },
  created() {
    let date = new Date().toLocaleDateString('en-us',)
    this.formatDate(date)
    if(this.date) {
      this.dateMonth = new Date(this.date).toLocaleString('default', { month: 'short' })
      this.dateDay = new Date(this.date).toLocaleString('default', { day: 'numeric' })
    }
  },
  computed: {
    returnDataStatus() {
      return this.dateNow > this.date
    },
  },
  methods: {
    formatDate(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.dateNow = [year, month, day].join('-');
    },
    coordinates() {
      document.body.classList.add("modal-open");
      this.$emit('coordinates', this.target)
    }
  }
};
</script>

<style lang="scss" scoped>
.festivals-item {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 27px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 576px) {
    flex-direction: column;
  }

  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    @media (max-width: 768px) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__disabled {
    opacity: 0.6;
  }

  &__text {
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    @media (max-width: 992px) {
      font-size: 16px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 28px;
    }

    &--lh {
      @media (max-width: 576px) {
        line-height: 28px;
      }
    }

    &--gathering {
      position: relative;

      &::before {
        content: "";
        width: 1px;
        height: 16px;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        left: -11px;
        top: 50%;
        transform: translate(-50%, -50%);
        display: none;
        @media (max-width: 576px) {
          display: block;
        }
      }
    }

    span {
      font-weight: 400;
    }

    &--inline {
      vertical-align: baseline;
      @media (max-width: 576px) {
        display: flex;
        align-items: baseline;
        line-height: 24px;
        margin-bottom: 5px;
      }
    }

    &--small {
      font-weight: 400;
      font-size: 12px;
      margin-top: 2px;

      @media (max-width: 768px) {
        margin-top: 0;
      }
    }

    &--mob {
      @media (max-width: 992px) {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &--leading {
      line-height: 23px;
    }

    &--date {
      @media (max-width: 576px) {
        padding-right: 23px;
        position: relative;
      }


    }

    &--online {
      margin-top: 8px;
      line-height: 24px;
    }
  }

  &__btn {
    max-width: 236px;
    cursor: pointer;
    justify-content: space-between;
    @media (max-width: 992px) {
      max-width: 210px;
    }
    @media (max-width: 768px) {
      max-width: 195px;
    }
    @media (max-width: 576px) {
      max-width: 100%;
      margin-top: 11px;
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
  }

  &__circle {
    width: 6px;
    height: 6px;
    background: #24a3df;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__address {
    display: flex;
    align-items: center;
    margin-right: 10px;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__icon {
    fill: #b7d2ff;
    width: 13px;
    height: 18px;
    margin-right: 7px;
  }

  &__map {
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 8px;

    &:hover {
      color: $purple;

      .svg-icon {
        fill: $purple;
      }
    }

    &:active {
      color: #863fc2;

      .svg-icon {
        fill: #863fc2;
      }
    }

    @media (max-width: 992px) {
      margin-top: 0;
    }
    @media (max-width: 576px) {
      margin: 0;
    }
  }

  &__map-text {
    line-height: initial;
    border-bottom: 1px dotted #000;
  }

  &__hint {
    display: inline-flex;
    margin-left: 7px;
    @media (max-width: 576px) {
      display: flex;
      margin-left: auto;
    }
  }

  &__box {
    &:first-child {
      margin-right: 30px;
    }

    &--flex {
      display: flex;
      width: 58%;
      justify-content: space-between;
      @media (max-width: 992px) {
        flex-direction: column;
        width: 45%;
      }
      @media (max-width: 576px) {
        width: auto;
        order: -1;
      }
    }

    @media (max-width: 768px) {
      margin-right: 20px;
      &:first-child {
        margin-right: 20px;
        min-width: 21%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 576px) {
      display: flex;
      width: 100%;
      margin-right: 0;
      &:first-child {
        margin-right: 0;
      }
    }

    &--mob-hide {
      @media (max-width: 576px) {
        display: none;
      }
    }

    &--mob-show {
      display: none;
      @media (max-width: 576px) {
        display: flex;
      }
    }
  }

  &__box-inner {
    margin-right: 7%;
    max-width: 385px;

    &:last-child {
      margin-right: 0;
    }

    &--width {
      //max-width: initial;
      min-width: 250px;
      max-width: 250px;
      @media (max-width: 992px) {
        display: flex;
        flex-wrap: wrap;
      }
      @media (max-width: 768px) {
        min-width: auto;
        max-width: initial;
      }
      @media (max-width: 576px) {
        min-width: initial;
      }
    }

    @media (max-width: 768px) {
      margin-right: 0;
      max-width: initial;
    }
    @media (max-width: 576px) {
      max-width: initial;
    }

    &--online {
      display: flex;
      flex-direction: column;

      @media (max-width: 992px) {
        margin-top: 10px;
        flex-direction: row;
        align-items: center;
      }
      @media (max-width: 576px) {

        margin-top: 0;
      }
    }
  }

  &__metros {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__bucket {
    display: flex;
    align-items: center;
    margin-left: 5px;

    span {
      margin-right: 7px;
    }

    @media (max-width: 576px) {
      margin-right: auto;
      margin-left: 7px;
    }
  }

  &__online {
    font-weight: 700;
    font-size: 18px;
    padding: 2px 4px;
    color: #fff;
    background: $purple;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}

.hidden-btn {
  visibility: hidden;
  @media (max-width: 576px) {
    display: none;
  }
}
</style>
