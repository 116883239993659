<template>
  <div>
    <div class="wrapper">
      <Header />
      <Main />

      <router-view />

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Main from "@/components/Main";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Main,
    Header,
  },
}
</script>

<style>
@font-face {
  font-family: 'Montserrat';
  src: url('~@/assets/fonts/Montserrat-Regular.eot');
  src: url('~@/assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('~@/assets/fonts/Montserrat-Regular.woff') format('woff'),
  url('~@/assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('~@/assets/fonts/Montserrat-Bold.eot');
  src: url('~@/assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('~@/assets/fonts/Montserrat-Bold.woff') format('woff'),
  url('~@/assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Solomon';
  src: url('~@/assets/fonts/SolomonSans-Black.eot');
  src: url('~@/assets/fonts/SolomonSans-Black.eot?#iefix') format('embedded-opentype'),
  url('~@/assets/fonts/SolomonSans-Black.woff') format('woff'),
  url('~@/assets/fonts/SolomonSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-open {
  overflow: hidden;
}

</style>
