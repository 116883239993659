<template>
  <Select :selected="selectedLabels" >
    <div class="grid">
      <Checkbox
        v-for="checkbox in filterPlaces"
        :key="checkbox.id"
        :name="checkbox.name"
        :metro="checkbox.metro"
        :metro-color="checkbox && checkbox.metro && checkbox.metro.color ? checkbox.metro.color : ''"
        :id="checkbox.id"
        :value="checkbox.value"
        @change.self="selectPlaces"
      />
    </div>
  </Select>
</template>
<script>
import Select from "@/components/Select";
import Checkbox from "@/components/Checkbox";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SelectPlaces",
  components: {
    Select,
    Checkbox
  },
  computed: {
    ...mapState(["filterPlaces"]),
    selectedLabels () {
      if (this.$store.state.placesLabels.length > 0) {
        return this.$store.state.placesLabels.join(', ')
      } else {
        return "все площадки";
      }
    },
  },
  methods: {
    ...mapActions(["setFilterPlaces"]),
    ...mapMutations(["selectPlaces"])
  },
  mounted() {
    this.setFilterPlaces()
  }
};
</script>
