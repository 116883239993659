<template>
  <Select :selected="selectedLabels">
    <div class="grid">
      <Checkbox
        v-for="checkbox in filterTypes"
        :key="checkbox.id"
        :id="checkbox.id"
        :name="checkbox.name"
        :description="checkbox.description"
        :value="checkbox.value"
        @change.self="selectType"
      />
    </div>
  </Select>
</template>

<script>
import Select from "@/components/Select";
import Checkbox from "@/components/Checkbox";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "SelectTypes",
  components: {
    Select,
    Checkbox
  },
  computed: {
    ...mapState(["filterTypes"]),
    selectedLabels () {
      if (this.$store.state.typesLabels.length > 0) {
        return this.$store.state.typesLabels.join(', ')
      } else {
        return "все форматы";
      }
    },
  },
  methods: {
    ...mapActions(["setFilterTypes"]),
    ...mapMutations(["selectType"])
  },
  mounted() {
    this.setFilterTypes();
  }
};
</script>
