<template>
  <div class="festivals-list">
    <div class="festivals-list__lists">
      <div class="festivals-list__item">
        <div class="festivals-list__header" v-show="!$store.state.error">
          <div class="festivals-list__box">
            <div class="title festivals-list__title">
              Афиша {{ $store.state.currentDate.day }} {{ $store.state.currentDate.month }},
              {{ $store.state.currentDate.week }}
            </div>
          </div>
          <div class="hint festivals-item__hint">
            <svg-icon name="bill" class="hint__icon"/>
            <p class="hint__text hint__text--hidden">Что это значит?</p>
            <p class="hint__text hint__text--mob">Что это?</p>
            <div class="hint__item hint__item--width">
              На выходе будет ведро, куда можно положить любую купюру, чтобы
              отблагодарить комиков
            </div>
          </div>
        </div>

        <div class="festivals-list__items" v-if="!isEventsLoading">
          <FestivalsItem
              v-for="item in $store.state.events"

              :day="item.day"
              :month="item.month"
              :description="item.subtitle"
              :key="item.id"
              :time="item.time"
              :gathering="item.collection_time"
              :title="item.title"
              :no_tickets="item.no_tickets"
              :metros="item.place && item.place.placeMetros ? item.place.placeMetros : []"
              :hint=" item && item.type && item.type.description ? item.type.description : ''"
              :btn_name="
              item.intickets == 0 ? item.btn_name :
              item.link_other_resource ? item.btn_name :
              item.cost && item.cost.max === item.cost.min ? `Билеты ${item.cost.min}&nbsp;₽` :
              item.cost && item.cost.min != item.cost.max ? `Билеты от&nbsp;${item.cost.min}&nbsp;₽` :
              item.sold_out == true ? 'Все продано' : item.btn_name
              "
              :bucket="item.bucket"
              :online="item.is_online"
              :place="item.place && item.place.name ? item.place.name : ''"
              :hint_overhead="item.hint_overhead"

              :sold_out="item.sold_out"
              :cost="item.cost"

              :link_other_resource="item.link_other_resource"
              :href="item.other_resource == 1 ? item.link_other_resource : item.show_id ? `https://iframeab-pre4328.intickets.ru/seance/${item.show_id}` : item.link_intickets"

              :target-timepad='item.timepad_id == "" ? "" : `{"event_id" : ${item.timepad_id}}`'
              :intickets-link="item.link_intickets ? item.link_intickets == '' ? '' : item.link_intickets : ''"

              @coordinates="eventCoordinates(item.place.latitude, item.place.longitude, item.place.name, item.place.address)"
          />
<!--          :btn_name="item.btn_name || item.cost && item.cost.length && item.cost.min == item.cost.max ? `Билеты ${item.cost.min}&nbsp;₽` : item.cost.length ? `Билеты от ${item.cost.min}&nbsp;₽` : ''"-->


<!--          :href="item.intickets == 1 ? item.link_intickets : '' ? item.other_resource == 1 : item.link_other_resource"-->


        </div>
        <div class="preloader" v-else>
          <img src="@/assets/images/preloader.svg" alt="preloader" class="preloader__img">
          <div class="preloader__text">загрузка...</div>
        </div>
      </div>
    </div>

    <div class="festivals-list__wrapper-btn">
      <button
          class="button button--pink festivals-list__btn"
          @click="nextDay()"
          v-if="$store.state.currentDate.nextDay"
      >
        смотреть {{ $store.state.currentDate.nextDay }} {{ $store.state.currentDate.nextMonth }}
        <svg-icon name="arrow" class="button__arrow"></svg-icon>
      </button>
    </div>

    <Modal v-model:show="dialogVisible">
      <GoogleMapLoad/>
    </Modal>

  </div>
</template>

<script>
import FestivalsItem from "@/components/FestivalsItem";
import SvgIcon from "@/components/SvgIcon";
import {mapActions, mapMutations, mapState} from "vuex";
import Modal from "@/components/Modals/Modal";
import GoogleMapLoad from "@/components/GoogleMapLoad";

export default {
  name: "FestivalsList",
  components: {GoogleMapLoad, Modal, SvgIcon, FestivalsItem},
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    ...mapState(["isEventsLoading", "today", "currentDate"]),
  },
  methods: {
    ...mapMutations(["nextDay"]),
    ...mapActions(["fetchEvents"]),
    ...mapMutations(["getCoordinates"]),
    eventCoordinates(latitude, longitude, name, address) {
      let coord = {
        lat: +latitude,
        lng: +longitude,
        name: name,
        address: address,
      }
      this.getCoordinates(coord)

      this.dialogVisible = true
    },
  },
};
</script>

<style lang="scss" scoped>
.festivals-list {
  position: relative;
  margin-top: 50px;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  @media (max-width: 576px) {
    margin-top: 0;
    z-index: 26;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__bell {
    width: 35px;
    height: auto;
    object-fit: contain;
    margin-right: 21px;
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__icon {
    width: 19px;
    height: 23px;
    margin-right: 13px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
  }

  &__hint {
    display: flex;
    align-items: center;
  }

  &__hint-text {
    font-size: 12px;
    line-height: 16px;
    color: #000;
    border-bottom: 1px dotted #000;
  }

  &__wrapper-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  &__btn {
    display: inline-flex;
    width: auto;
    min-width: 240px;
    margin: 107px auto 0 auto;
    @media (max-width: 768px) {
      margin-top: 80px;
    }
    @media (max-width: 576px) {
      margin-top: 60px;
    }
  }

  &__item {
    margin-bottom: 61px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      margin-bottom: 50px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
}

.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  &__img {
    width: 46px;
    height: 60px;
    margin-bottom: 20px;
    display: block;
  }

  &__text {
  }

}
</style>
