<template>
  <div class="modal" v-if="show" @click.stop="hideDialog">
    <div @click.stop class="modal__content">
      <button @click.stop="hideDialog" class="close-modal"></button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import toggleMixin from "@/assets/mixins/toggleMixin";
import {mapMutations} from "vuex";

export default {
  name: "Modal",
  mixins: [toggleMixin],
  methods: {
    ...mapMutations(["clearCoordinates"]),
    hideDialog() {
      document.body.classList.remove("modal-open");
      this.$emit('update:show', false);
      this.clearCoordinates();
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 30;
  position: fixed;
  display: flex;

  &__content {
    margin: auto;
    background: #fff;
    max-width: 1066px;
    width: 100%;
    height: 561px;
    display: block;
    position: relative;
    @media (max-width: 1200px) {
      max-width: calc(100vw - 50px);
    }
    @media (max-width: 992px) {
      height: calc(100vh - 90px);
    }
    @media (max-width: 576px) {
      max-width: 100vw;
      height: 100vh;
    }
  }
}
.close-modal {
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/icons/close.svg") center /contain no-repeat;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 15;
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
  @media (max-width: 768px) {
    width: 18px;
    height: 18px;
  }
  @media (max-width: 576px) {
    width: 16px;
    height: 16px;
  }
}
</style>
