<template>
  <Festivals/>
</template>

<script>
import Festivals from "@/components/Festivals";
export default {
  name: "Home",
  components: {Festivals}
}
</script>

<style scoped>

</style>