import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import axios from "axios";
import router from './router';
import VueAxios from "vue-axios";
import VueGoogleMaps from '@fawmi/vue-google-maps'

// Styles
import "@/assets/styles/main.scss";


const app = createApp(App);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

app
    .use(VueAxios, axios)
    .use(store)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBiD0gzZ99Za2EjzvTGHvYAPkIRoGedMNs'
        }
    })
  .use(router)
app.mount("#app");
