export const volumeTriggerModule = {
    state: () => ({
        volumeTrigger: false,
    }),
    mutations: {
        offVolumeTrigger(state) {
            state.volumeTrigger = false;
        },
        onVolumeTrigger(state) {
            state.volumeTrigger = true;
        }
    },
    namespaced: true,
};
