<template>
  <Select :selected="selectedLabels">
    <div class="grid grid--col">
      <Checkbox
        v-for="checkbox in filterVisits"
        :key="checkbox.id"
        :name="checkbox.type"
        :description="checkbox.description"
        :id="checkbox.id"
        :value="checkbox.value"
        @change.self="selectVisits"
      />
      <div class="filters__hint">
        <svg-icon
            name="bill"
            class="filters__hint-img"/>
        <div class="filters__hint-text">
          Пометка «Купюра»: на выходе будет ведро, куда можно положить любую
          купюру, чтобы отблагодарить комиков
        </div>
      </div>
    </div>
  </Select>
</template>

<script>
import Select from "@/components/Select";
import Checkbox from "@/components/Checkbox";
import {mapActions, mapMutations, mapState} from "vuex";
import SvgIcon from "../SvgIcon";

export default {
  name: "SelectVisits",
  components: {
    SvgIcon,
    Select,
    Checkbox
  },
  computed: {
    ...mapState(["filterVisits"]),
    selectedLabels () {
      if (this.$store.state.visitsLabels.length > 0) {
        return this.$store.state.visitsLabels.join(', ')
      } else {
        return "любая стоимость";
      }
    },
  },
  methods: {
    ...mapActions(["setFilterVisits"]),
    ...mapMutations(["selectVisits"])
  },
  mounted() {
    this.setFilterVisits()
  }
};
</script>

<style scoped></style>
