<template>
  <div class="festivals" >
    <div class="container">
      <div class="breadcrumbs">
        <button class="breadcrumbs__btn" @click="$router.push('/')">
          <svg-icon name="arrow" class="button__arrow"/>
          <p>ко всем событиям</p>
        </button>
      </div>
      <div class="title festivals__title">{{ data.name }}</div>
      <div class="title festivals__description">{{ data.description }}</div>
      <div v-if="$store.state.error">
        <div class="error">{{ $store.state.error }}</div>
      </div>
      <FestivalListDynamic :events="data.events"/>
    </div>
  </div>
</template>

<script>
import FestivalListDynamic from "@/components/FestivaslListDynamic";
import axios from "axios";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "FestivalsDynamic",
  components: {SvgIcon, FestivalListDynamic},
  data() {
    return {
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        let slug = this.$route.params.slug
        const res = await axios.get(`/api/v1/package?slug=${slug}`);
        if (res.data) {
          this.data = res.data
          console.log(this.data, 'this.data')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.festivals {
  padding: 87px 0 179px 0;
  position: relative;
  @media (max-width: 1450px) {
    padding: 80px 0 170px 0;
  }
  @media (max-width: 992px) {
    padding-bottom: 150px;
  }
  @media (max-width: 768px) {
    padding-bottom: 110px;
    padding-top: 60px;
  }
  @media (max-width: 576px) {
    padding: 95px 0 80px 0;
    //padding-top: 40px;
  }
  &__title {
    margin-bottom: 10px;
    padding-right: 40px;
    position: relative;
    display: inline-block;
    @media (max-width: 992px) {
      margin-bottom: 26px;
    }
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
  }
  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.error {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  margin-top: 50px;
  @media (max-width: 576px) {
    font-size: 18px;
  }
}

.breadcrumbs {
  margin-bottom: 40px;
  &__btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    @media (max-width: 576px) {
      gap: 6px;
    }
    .button__arrow {
      transform: rotate(180deg);
      margin-left: 0;
      @media (max-width: 576px) {
        width: 10px;
      }
    }
    & p {
      font-weight: 600;
      color: $purple;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 576px) {
    margin-bottom: 15px;
  }
}
</style>
