import {createStore} from "vuex";
import axios from "axios";
import {storiesModule} from "./storiesModule";
import {volumeTriggerModule} from "./volumeTriggerModule";
import deepmerge from "deepmerge";
export default createStore({
    state() {
        return {
            // Площадка
            places: [],
            placesLabels: [],
            filterPlaces: [
                {
                    id: 1,
                    name: "",
                    description: "",
                    value: false
                }
            ],

            // Тип
            types: [],
            typesLabels: [],
            filterTypes: [
                {
                    id: 1,
                    name: "",
                    description: "",
                    value: false
                }
            ],

            // Вход
            visits: [],
            visitsLabels: [],
            filterVisits: [
                {
                    id: 1,
                    type: "",
                    description: "",
                    value: false
                }
            ],

            selectedFilterDates: [""],
            filterDates: [
                {
                    id: 1,
                    name: "",
                    description: ""
                }
            ],

            dates: [],
            events: [],
            today: "",
            currentDate: [],
            nextDay: "",
            hiddenEvents: false,
            isEventsLoading: false,
            coordinates: [],
            coodinatesCenter: {},
            verifiedDates: [],
            error: ''
        };
    },
    mutations: {
        SET_FILTER_PLACES(state, places) {
            state.filterPlaces = places.map(place => ({
                name: place.name,
                description: place.description,
                id: place.id,
                metro: place.metro,
                value: false,
            }))
        },
        SET_FILTER_TYPES(state, types) {
            state.filterTypes = types.map(type => ({
                name: type.name,
                description: type.description,
                id: type.id,
                value: false
            }));
        },
        SET_FILTER_VISITS(state, visits) {
            const array = Object.values(visits)

            state.filterVisits = array.map(visit => ({
                id: visit.id,
                type: visit.type,
                description: visit.description,
                value: false,
            }))
        },
        SET_DATES(state, {filterDates, firstDay}) {
            state.dates = filterDates;
            state.today = firstDay;
        },
        SET_EVENTS(state, events) {
            state.events = events;
        },

        resetFilters(state) {
            state.places = [];
            state.types = [];
            state.visits = [];
            state.placesLabels = [];
            state.typesLabels = [];
            state.visitsLabels = [];
            state.filterPlaces = state.filterPlaces.map(item => ({
                id: item.id,
                name: item.name,
                description: item.description,
                metro: item.metro,
                value: false
            })),
                state.filterTypes = state.filterTypes.map(item => ({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    value: false
                }));
            state.filterVisits = state.filterVisits.map(item => ({
                id: item.id,
                type: item.type,
                description: item.description,
                value: false,
            }))
            state.hiddenEvents = false;

            window.history.pushState(null, document.title, `${window.location.pathname}`);

            this.dispatch("fetchEvents", state.today)
        },
        selectPlaces(state, {id, value, name}) {
            const index = state.filterPlaces.findIndex(x => x.id === id)
            state.filterPlaces[index].value = value;

            if (state.places.includes(id)) {
                state.places = state.places.filter(place => place !== id);
                state.placesLabels = state.placesLabels.filter(placeName => placeName !== name);
            } else {
                state.places.push(id);
                state.placesLabels.push(name)
            }
            // console.log(state.placesLabels)
        },
        selectType(state, {id, value, name}) {
            const index = state.filterTypes.findIndex(x => x.id === id);
            state.filterTypes[index].value = value;
            if (state.types.includes(id)) {
                state.types = state.types.filter(type => type !== id);
                state.typesLabels = state.typesLabels.filter(typeName => typeName !== name);
            } else {
                state.types.push(id);
                state.typesLabels.push(name)
            }
        },
        selectVisits(state, {id, value, name}) {
            const index = state.filterVisits.findIndex(x => x.id === id);
            state.filterVisits[index].value = value;
            if (state.visits.includes(id)) {
                state.visits = state.visits.filter(visit => visit !== id);
                state.visitsLabels = state.visitsLabels.filter(visitName => visitName !== name);
            } else {
                state.visits.push(id);
                state.visitsLabels.push(name)
            }
        },
        hideEmptyEvents(state) {
            if (state.hiddenEvents === false) {
                for(let i = state.events.length - 1; i >= 0; i--) {
                    if (state.events[i].sold_out  == true) {
                        state.events.splice(i, 1);
                    } else if (state.events[i].no_tickets  == 1) {
                        state.events.splice(i, 1);
                    }
                }
                state.hiddenEvents = true;
            } else {
                this.dispatch("fetchEvents", state.currentDate.date)
            }
        },
        nextDay(state) {
            const el = document.querySelector('.festivals')
            el.scrollIntoView({
                behavior: "smooth"
            });

            let current = new Date(state.currentDate.date);
            let next = new Date(current.getTime() + (24 * 60 * 60 * 1000)).toISOString().slice(0, 10);

            state.nextDay = next;

            if (state.dates.filter(e => new Date(e.date) >= new Date(next))[0]) {
                let findDates = state.dates.find(date => new Date(date.date) > new Date(state.currentDate.date));
                this.dispatch("fetchEvents", findDates.date)
            } else {
                this.dispatch("fetchEvents", state.currentDate.date)
            }

        },
        setLoading(state, bool) {
            state.isEventsLoading = bool;
        },
        getTimepadId(state, id) {
            state.timepadIdtimepadId = id;
        },
        getCoordinates(state, data) {
            state.coodinatesCenter = data;
            const markCoordinate = {
                position: data
            }
            state.coordinates.push(markCoordinate);
        },
        clearCoordinates(state) {
            state.coordinates = []
            state.coordinatesCenter = []
        },
        // SET_INTICKETS_DATA(state, data) {
        //     state.events = data
        // }
    },

    actions: {
        async setFilterPlaces({commit}) {
            try {
                const places = await axios.get("/api/v1/place");
                commit("SET_FILTER_PLACES", places.data);
            } catch (e) {
                console.log(e)
            }
        },
        async setFilterTypes({commit}) {
            try {
                const types = await axios.get("/api/v1/type-events");
                commit("SET_FILTER_TYPES", types.data);
            } catch (e) {
                console.log(e)
            }
        },
        async setFilterVisits({commit}) {
            try {
                const visits = await axios.get("/api/v1/type-visit");
                commit("SET_FILTER_VISITS", visits.data);
            } catch (e) {
                console.log(e)
            }
        },
        async getDates({commit, dispatch}, date) {

            try {
                const dates = await axios.get("/api/v1/events/date-events");
                const filterDates = dates.data.map(function (date) {
                    let currentDay = new Date(date.date + "T00:00:00");
                    let fullDay = currentDay.getDay();
                    let fullMonth = currentDay.getMonth();
                    const days = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
                    const months = [
                        "янв",
                        "фев",
                        "мар",
                        "апр",
                        "май",
                        "июн",
                        "июл",
                        "авг",
                        "сен",
                        "окт",
                        "ноя",
                        "дек"
                    ];
                    let dayWeek = days[fullDay];
                    let getMonth = months[fullMonth];
                    let day = currentDay.getDate();

                    const inputDay = new Date(date.date).setHours(0, 0, 0, 0);
                    const today = new Date().setHours(0, 0, 0, 0);

                    let obj = {
                        date: date.date,
                        week: dayWeek,
                        day: day,
                        month: getMonth,
                        disabled: inputDay < today,
                    };


                    return obj;
                });


                filterDates.forEach(date => {
                    const fullDay = new Date(date.date + "T00:00:00")
                    const nextDayFull = new Date(fullDay.setTime(fullDay.getTime() + (24 * 60 * 60 * 1000)));

                    const nextDay = nextDayFull.getDate();
                    const nextMonth = nextDayFull.getMonth();
                    const months = [
                        "января",
                        "февраля",
                        "марта",
                        "апреля",
                        "мая",
                        "июня",
                        "июля",
                        "августа",
                        "сентября",
                        "октября",
                        "ноября",
                        "декабря"
                    ];

                    date.nextDay = nextDay;
                    date.nextMonth = months[nextMonth];
                })

                let verificationDates = filterDates.filter(day => day.disabled === false);
                let firstDay = verificationDates[0]?.date;

                commit("SET_DATES", {filterDates, firstDay});

                dispatch("fetchEvents", date)

            } catch (e) {
                console.log(e)
            }
        },
        async fetchEvents({commit, state}, date = state.today) {

            state.hiddenEvents = false;

            const params = {
                date: date,
                place: state.places,
                type: state.types,
                sale: state.visits
            };

            let currentDay = new Date(date + "T00:00:00");
            let fullDay = currentDay.getDay();
            let fullMonth = currentDay.getMonth();
            const days = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
            const months = [
                "января",
                "февраля",
                "марта",
                "апреля",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря"
            ];

            let dayWeek = days[fullDay];
            let getMonth = months[fullMonth];
            let day = currentDay.getDate();

            const allVerifedDates = state.dates.filter(date => date.disabled == false);

            allVerifedDates.forEach(date => {
                const objDate = {
                    date: date.date,
                    day: date.day,
                }

                let index = state.verifiedDates.findIndex(x => x.date == date.date);
                index === -1 ? state.verifiedDates.push(objDate) : ''

            })

            let dateObj = {
                date: date,
                week: dayWeek,
                day: day,
                month: getMonth,
            };
            state.currentDate = dateObj;

            let dayList = []
            const dayDateList = []


            state.verifiedDates.forEach(item => {
                dayList.push(item.day)
                dayDateList.push(item.date)
            })

            for (let i = 0; i < dayList.length; i++) {
                if (new Date(dayDateList[i]) > new Date(state.currentDate.date)) {
                    dateObj.nextDay = dayList[i];
                    const date = new Date(dayDateList[i]);
                    dateObj.nextMonth = months[date.getMonth()];
                    break
                }
            }



            try {
                commit('setLoading', true);
                const response = await axios.get("/api/v1/events", {params});
                state.error = response.data.error;

                if (response && response.data && response.data.length) {
                    const modifedData = response?.data.map(data => {

                        let fullDate = new Date(data.date);
                        let fullMonth = fullDate.getMonth();
                        const months = [
                            "янв",
                            "фев",
                            "мар",
                            "апр",
                            "май",
                            "июн",
                            "июл",
                            "авг",
                            "сен",
                            "окт",
                            "ноя",
                            "дек"
                        ];

                        let getMonth = months[fullMonth];
                        let day = currentDay.getDate();

                        return {
                            id: data.id,
                            title: data.title,
                            subtitle: data.subtitle,
                            date: data.date,
                            time: data.time,
                            is_online: data.is_online,
                            collection_time: data.collection_time,
                            timepad: data.timepad,
                            timepad_id: data.timepad_id,
                            intickets: data.intickets,
                            int_code_org: data.int_code_org,
                            int_node: data.int_node,
                            no_tickets: data.no_tickets,
                            type_id: data.type_id,
                            bucket: data.bucket,
                            gst: data.gst,
                            gst_id: data.gst_id,
                            other_resource: data.other_resource,
                            link_other_resource: data.link_other_resource,
                            place: data.place,
                            btn_name: data.btn_name,
                            type: data.type,
                            hint_overhead: data.type.name,
                            link_intickets: data.link_intickets,
                            hall: data.hall,
                            day: day,
                            month: getMonth,
                        }
                    })
                    commit("SET_EVENTS", modifedData);
                } else{
                    commit("SET_EVENTS", []);
                }

                // const foundEl = newItems.find(ne => ce.place?.id_intickets === ne.venue_id);
                /* NEW INTICKETS API */
                // eslint-disable-next-line no-inner-declarations
                function merge(currentItems, newItems) {
                    const outputEvents = [];
                    currentItems.forEach((currentItem) => {
                        // console.log(currentItem.time);
                        // const foundEl = newItems.find(ne => ce.date == ne.show_start.slice(0,10) && ce.time == ne.show_start.slice(-4, 4));
                        
                        const dateE = currentItem.date + 'T' + currentItem.time;

                        const foundEl = newItems.find(newItem => currentItem.place.id_intickets === newItem.venue_id && dateE == newItem.show_start);
                        //const foundEl = newItems.find(newItem => currentItem.int_node === newItem.show_id);
                    // && ce.time == ne.show_start &&  ce.place?.id_intickets === ne.venue_id
                         //console.log(foundEl)
                        const outputEvent = foundEl ? deepmerge({...currentItem}, foundEl) : {...currentItem};
                        outputEvents.push(outputEvent);
                    });
                    //console.log(outputEvents);
                    return outputEvents;
                }
                const responseInt = await axios.get('https://api.intickets.ru/widget/v1/shows', {
                    headers: {
                        Authorization: 'Bearer ' + 'be886cda-dee4-755f-9378-f5449917611d'
                    }
                });


                //console.log(state.events);
                //console.log(responseInt.data);
                let newModifedData = merge(state.events, responseInt.data);
                //console.log(newModifedData);
                commit("SET_EVENTS", newModifedData);
                /* ---- */

            } catch (e) {
                console.log(e)
            } finally {
                commit('setLoading', false)
            }




        },
        // async getInticketsData({state}) {
        //
        //
        //     console.log(state.events.includes(item => item.place.timepad_id))
        //
        //
        //     // console.log(response.data)
        //     //
        //     // console.log(state.events)
        //
        //     // Object.prototype.assignValuesFrom = function(sourceObj) {
        //     //     assign(this, sourceObj);
        //     //
        //     //     function assign(dst, src) {
        //     //         for (let key in src) {
        //     //             if (!Object.prototype.hasOwnProperty.call(dst, key) || (typeof src[key] === 'function'))
        //     //                 continue;
        //     //             if (src[key] instanceof Array) {
        //     //                 for (let i = 0; i < src[key].length; i++) {
        //     //                     if (!(src[key][i] instanceof Object))
        //     //                         dst[key][i] = src[key][i];
        //     //                 }
        //     //                 continue;
        //     //             }
        //     //             if (src[key] instanceof Object) {
        //     //                 assign(dst[key], src[key]);
        //     //                 continue;
        //     //             }
        //     //             dst[key] = src[key];
        //     //         }
        //     //     }
        //     // }
        //     //
        //     // let copy = Object.assign({}, state.events);
        //     // // копируем из объекта params значения тех полей, которые присутствуют в копии changed
        //     // copy.assignValuesFrom(response.data);
        //     // // копируем значение из еще одного объекта, в литеральной записи
        //     // copy.assignValuesFrom({
        //     //     sold_out: true,
        //     // });
        //
        //
        //     // console.log(copy)
        //     // commit("SET_INTICKETS_DATA", copy);
        // }



    },

    modules: {
        stories: storiesModule,
        volume: volumeTriggerModule,
    }
});
