<template>
  <div>
    <div class="videos-wrapper">

      <swiper
          class="videos"
          :breakpoints="swiperBreakpoints"
          :slides-per-view="5"
          :space-between="9"
      >
        <swiper-slide
            v-for="(item, i) in this.stories"
            :key="i"
            @click="showStories(item.slides), goLink(item.link)"
        >
          <div class="videos__item">
            <img :src="item.preview_img">
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <StoriesSlider @hideStories="hideStories" :story="this.slides" v-model:show="storiesVisible"
                   v-if="storiesVisible && this.slides != undefined && this.slides.length"/>
  </div>
</template>

<script>
import StoriesSlider from "@/components/StoriesSlider";
import {mapActions, mapMutations, mapState} from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue';
// import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css'



export default {
  name: "Videos",
  components: {
    Swiper,
    SwiperSlide,
    StoriesSlider
  },
  data() {
    return {
      storiesVisible: false,
      swiperBreakpoints: {
        "0": {
          slidesPerView: "auto",
          "allowTouchMove": true,
        },
        "1200": {
          "slidesPerView": 5,
          "allowTouchMove": false,
        }
      }
    }
  },
  methods: {
    ...mapMutations('stories', [
      'setSlides',
      'clearSlides',
    ]),
    ...mapActions({
      fetchStories: 'stories/fetchStories'
    }),
    showStories(slides) {
      this.setSlides(slides);
      this.storiesVisible = true;
    },
    hideStories() {
      this.storiesVisible = false;
      this.clearSlides();
    },
    goLink(link) {
      if (link.length > 0) {
        window.open(link, '_blank');
      }
    },
  },
  computed: {
    ...mapState('stories', {
      stories: state => state.stories,
      slides: state => state.slides
    })
  },
  mounted() {
    this.fetchStories();
  }
}
</script>

<style lang="scss">
.videos-wrapper {
  position: absolute;
  right: 4%;
  top: 50%;
  z-index: 25;
  transform: translateY(-50%);
  @media (max-width: 1800px) {
    //top: 58%;
  }
  @media (max-width: 1450px) {
    right: 1.2%;
  }
  @media (max-width: 1200px) {
    //top: 70%;
    top: 95%;
    width: 550px;
    right: 0;
  }
  @media (max-width: 992px) {
    //top: 93%;
    top: 94%;

    width: 360px;
  }
  @media (max-width: 768px) {
    top: 98%;
  }
  @media (max-width: 680px) {
    width: 330px;
  }
  @media (max-width: 630px) {
    right: initial;
    left: 0;
    top: 105%;
    width: 100%;
  }
}
.videos {
  //display: none;
  cursor: pointer;
  @media (max-width: 992px) {
    width: 100%;
    padding-right: 15px;
  }
  @media (max-width: 768px) {
    padding-right: 10px;
  }
  @media (max-width: 576px) {
    padding-right: 25px;
  }

  .swiper-wrapper {
    display: flex;
  }

  .swiper-slide {
    width: 120px !important;
    height: 180px;
    @media (max-width: 1800px) {
      //width: 140px !important;
      //height: 210px;
    }
    @media (max-width: 1550px) {
      width: 120px !important;
      height: 180px;
    }
    @media (max-width: 992px) {
      width: 86px !important;
      height: 129px;
    }
    @media (max-width: 768px) {
      width: 86px !important;
      height: 128px;
    }
    @media (max-width: 576px) {
      width: 76px !important;
      height: 114px;
      &:first-of-type {
        margin-left: 15px;
      }
    }
  }

  &__item {
    display: block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
