<template>
  <div>
    <div class="overlay" @click="hideStories"></div>
    <div class="daily-stories-wrapper" v-if="show">
      <div class="daily-stories-wrapper__inner">
        <button class="daily-stories-close" @click.stop="hideStories">
          <svg-icon name="close"/>
        </button>
        <div class="daily-stories">
          <div class="daily-stories__outer" ref="outer">
            <div class="daily-stories__container" ref="container">
              <div
                  v-for="(item, i) in story"
                  :key="i"
                  ref="slide"
                  class="slide"
                  :class="{'video' : item.type == 1}"
                  :data-timeout="item.duration"
              >
                <img
                    v-if="item.type == 0"
                    :src="item.file"
                />
                <video
                    webkit-playsinline
                    playsinline
                    v-if="item.type == 1"
                    :src="item.file"
                    preload="true"
                    muted="muted"
                ></video>
                <div class="stories-panel__time">{{ item.time_passed }}</div>
                <a
                    @click="toggleSliderAutoplay"
                    v-if="item.btn_type != 2"
                    target="_blank"
                    :href="item.btn_type == 0 ? item.btn_link : item.event.intickets == 1 ? item.event.link_intickets : '' ? item.event.other_resource == 1 : item.event.link_other_resource"
                    class="button button--pink daily-stories__btn"
                    :class="{ disabled: item.event && item.event.no_tickets == 1, 'twf' : item.event && item.event.timepad_id, 'abiframelnk' : item.event && item.event.link_intickets}"
                    :data-twf-target-state='item && item.event && item.event.timepad_id ? `{"event_id" : ${item.event.timepad_id}}` : ""'
                >
                  {{ item.btn_text }}
                  <svg-icon name="arrow"/>
                </a>
              </div>
            </div>
          </div>

          <div class="progress-bars" data-count="4">
            <div
                v-for="(item, i) in story"
                :key="i"
                class="bar"
                :data-index="i">
              <span :style="{animationDuration: `${item.duration + 'ms'}`}"></span>
            </div>
          </div>
        </div>
        <span
            class="arrow-slide"
            id="prev-slide"
            @click.prevent="prevSlide"
        >
      <svg-icon name="arrow"/>
    </span>
        <span
            class="arrow-slide"
            id="next-slide"
            @click.prevent="nextSlide"
        >
      <svg-icon name="arrow"/>
    </span>
        <div
            class="central-area"
            ref="centralArea"
            data-state="playing"
            @click.prevent="toggleSliderAutoplay"
        ></div>
        <div class="stories-panel stories-panel--end">
          <div class="stories-panel__box">
            <div class="stories-panel__icon">
              <svg-icon
                  :name="togglePlay"
                  class="stories-panel__play"
              />
            </div>
            <div class="stories-panel__icon">
              <svg-icon
                  :name="volume"
                  class="stories-panel__volume"
                  @click.prevent="offVolume"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import {mapMutations, mapState} from "vuex";
export default {
  name: "StoriesSlider",
  components: {SvgIcon},
  props: {
    story: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: undefined,
      timersSet: [],
      dataSet: [],
      current_index: 0,
      move_distance: 0,
      togglePlay: 'pause',
      volume: 'volume-off',
      // volumeTrigger: false,
    }
  },
  computed: {
    ...mapState('volume', {
      volumeTrigger: state => state.volumeTrigger
    })
  },
  methods: {
    ...mapMutations('volume', [
       'offVolumeTrigger',
       'onVolumeTrigger'
    ]),
    imgs() {
      const imgs = document.querySelectorAll(".daily-stories .slide");
      return imgs
    },
    totalImgs() {
      const total_imgs = this.imgs().length;
      this.volume = 'volume-off';
      return total_imgs
    },
    bars() {
      const bars = document.querySelectorAll(".progress-bars .bar");
      return bars
    },

    reload() {
      window.location.reload(false);
    },
    init() {
      let container_width = this.$refs.outer.clientWidth;
      this.$refs.container.style.width = `${container_width * this.totalImgs()}px`;
    },
    collections() {
      for (let i = 0; i < this.totalImgs(); ++i) {
        this.timersSet.push(this.imgs()[i].getAttribute("data-timeout"));
        if (this.imgs()[i].classList.contains("video")) {
          this.dataSet.push(this.imgs()[i].querySelector("video").getAttribute("src"));
        } else {
          this.dataSet.push(this.imgs()[i].querySelector("img").getAttribute("src"));
        }
      }
    },
    slidesAutoPlay() {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        if (this.current_index < this.totalImgs() - 1) {
          this.nextSlide();
        } else {
          // console.log("End of Story");
          this.hideStories()
        }
      }, this.timersSet[this.current_index]);
    },
    setSlideActive(i) {
      const currentSlide = this.imgs()[i];

      this.imgs().forEach((el) => el.classList.remove("active"));
      currentSlide.classList.add("active");

      this.playVideo();
    },
    setBarActive(i) {
      this.bars().forEach((el, index) => {
        if (index >= i) {
          el.classList.remove("animate");
        }
        if (index < i) {
          el.classList.add("seen");
          el.classList.remove("animate");
        } else {
          el.classList.remove("seen");
        }
      });

      this.bars()[i].classList.add("animate");
    },
    setActive() {
      if (this.current_index < this.totalImgs() - 1) {
        // if ain't LAST
        this.setBarActive(parseInt(this.current_index, 10) + 1);
      } else {
        this.setBarActive(0);
      }

      setTimeout(() => {
        this.setBarActive(this.current_index);
        this.setSlideActive(this.current_index);
      }, 1);
    },
    nextSlide() {
      if (this.current_index < this.totalImgs() - 1) {
        // check if it isn't LAST slide

        if (document.body.classList.contains("menu-open")) return; // Disallow slides switching if context menu is open

        document.body.classList.remove("paused"); // Un-Pause slider

        this.stopVideo(); // Stop video on current slide if any were playing

        this.current_index++;

        this.move_distance = -(this.current_index * this.$refs.outer.clientWidth);
        this.switchImages(this.move_distance);
        // updateSaveImgSrc();

        this.setActive();

        this.slidesAutoPlay();

        this.togglePlay = 'pause'
      }
    },
    prevSlide() {
      if (this.current_index >= 0) {
        // check if it isn't FIRST slide

        if (document.body.classList.contains("menu-open")) return; // Disallow slides switching if context menu is open

        document.body.classList.remove("paused"); // Un-Pause slider

        this.stopVideo(); // Stop video on current slide if any were playing

        if (this.current_index > 0) {
          // decrease index only if larger than 0
          this.current_index--;
        }

        this.move_distance = -(this.current_index * this.$refs.outer.clientWidth);
        this.switchImages(this.move_distance);
        // updateSaveImgSrc();

        this.setActive();

        this.slidesAutoPlay();

        this.togglePlay = 'pause'
      }
    },
    switchImages(scrolled_number) {
      const distance = scrolled_number || this.move_distance;
      this.$refs.container.style.transform = `translate3d(${distance}px, 0px, 0px)`;
    },
    pauseVideo() {
      if (this.isVideo()) {
        const v = this.imgs()[this.current_index].querySelector("video");
        v.muted = true;
        v.pause();
        this.volume = 'volume-off';
      }
    },
    playVideo() {
      if (this.isVideo()) {
        const v = this.imgs()[this.current_index].querySelector("video");
        v.play();
        if (this.volumeTrigger) {
          v.muted = false;
          this.volume = 'volume-on'
        } else {
          this.volume = 'volume-off'
        }
      }
    },
    stopVideo() {
      if (this.isVideo()) {
        const v = this.imgs()[this.current_index].querySelector("video");
        v.pause();
        v.currentTime = 0;
        // this.volume = 'volume-off'
      }
    },
    offVolume() {
      if (this.isVideo()) {
        const v = this.imgs()[this.current_index].querySelector("video");
        if (this.volumeTrigger) {
          v.muted = true;
          this.volume = 'volume-off';
          // this.volumeTrigger = false;
          this.offVolumeTrigger();
        } else {
          v.muted = false;
          this.volume = 'volume-on';
          // this.volumeTrigger = true;
          this.onVolumeTrigger();
        }
      }
    },
    cancelAnimation() {
      clearTimeout(this.timer);
    },
    isVideo() {
      if (this.imgs()[this.current_index].classList.contains("video")) {
        return this.imgs()[this.current_index].classList.contains("video");
      }
    },
    // Toggle Play/Pause of Carousel
    toggleSliderAutoplay(e) {
      const state = e.target.getAttribute("data-state");

      if (state === "paused") {
        this.$refs.centralArea.setAttribute("data-state", "playing");
        document.body.classList.remove("paused");

        this.setActive();

        this.slidesAutoPlay();

        this.stopVideo();
        this.playVideo();
        this.togglePlay = 'pause'

      } else {
        this.$refs.centralArea.setAttribute("data-state", "paused");
        document.body.classList.add("paused");
        this.cancelAnimation();
        this.pauseVideo();
        this.togglePlay = 'play'
      }
    },
    hideStories() {
      this.cancelAnimation();
      this.pauseVideo();
      this.stopVideo();
      this.$emit('hideStories', this.target);
    },
  },
  mounted() {
    window.addEventListener('resize', this.reload)
    this.init();
    this.collections();

    this.setSlideActive(0);
    this.setBarActive(0);

    this.slidesAutoPlay();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.reload)
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(168, 92, 233, 0.6);
  z-index: 40;
  @media (max-width: 720px) {
    display: none;
  }
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    display: none;
  }
}
.daily-stories-wrapper {
  //top: 0;
  //bottom: 0;
  //right: 0;
  //left: 0;
  //z-index: 30;
  //position: fixed;
  //display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);

  position: fixed;
  height: 752px;
  z-index: 43;
  width: 422px;
  @media (max-width: 1300px) {
    width: 344px;
    height: 660px;
  }
  @media (max-width: 1024px) {
    width: 364px;
    height: 680px;
  }
  @media (max-width: 720px) {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    height: 100%;
    width: 100%;
  }
  //&::before {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  //background: rgba(168, 92, 233, 0.33);
  //  background: rgba(168, 92, 233, 0.6);
  //  //backdrop-filter: blur(5px);
  //}


  &__inner {
    width: 422px;
    height: 752px;
    position: relative;
    margin: auto;
    @media (max-width: 1300px) {
      width: 344px;
      height: 660px;
    }
    @media (max-width: 1024px) {
      width: 364px;
      height: 680px;
    }
    @media (max-width: 720px) {
      position: fixed;
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
      position: fixed;
      height: 100%;
      width: 100%;
    }
  }
}

.daily-stories {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 422px;
  height: 752px;
  margin: 0 auto;
  background: black;
  overflow: hidden;
  z-index: 30;

  &__outer {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 1300px) {
    width: 344px;
    height: 660px;
  }
  @media (max-width: 1024px) {
    width: 364px;
    height: 680px;
  }
  @media (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    width: 100%;
    height: 100%;
  }

  &__btn {
    padding: 0 22px 0 27px;
    width: calc(100% - 72px);
    margin: 0 36px;
    justify-content: space-between;
    position: absolute;
    bottom: 34px;
    left: 0;
    z-index: 5;
    .svg-icon {
      width: 16px;
      height: 12px;
    }
    @media (max-width: 576px) {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  }
}

.daily-stories__container {
  width: 100%;
  height: 100%;
  background: black;
  overflow: hidden;
  display: flex;
  position: relative;
  transition: transform 0.25s cubic-bezier(0.8, 0, 0.2, 1);
  will-change: auto;
  touch-action: manipulation;
  transform: translate3d(0px, 0px, 0px);
  backface-visibility: hidden;
}

.slide {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: opacity;
  opacity: 0;
  filter: blur(0);
  transition: opacity 0.5s ease, filter 0.25s ease;
}

.slide.active {
  opacity: 1;
}

body.menu-open .slide.active {
  filter: blur(10px);
}

.slide img,
.slide video {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  //object-fit: cover;
  object-fit: contain;

  object-position: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  pointer-events: none;
  @media (max-width: 720px) {
    //height: 100vh;
  }
}

.arrow-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  background: #fff;
  transition: opacity 0.25s ease;
  z-index: 30;
  -webkit-tap-highlight-color: transparent;
  .svg-icon {
    width: 14px;
    fill: #A85CE9;
  }

  &:hover {
    background: #A85CE9;

    .svg-icon {
      fill: #fff;
    }
  }
  @media (max-width: 720px) {

    .svg-icon {
      display: none;
    }
    &:hover {
      background: transparent;
    }
    background: transparent;
    height: calc(100% - 165px);
    border-radius: 0;
    width: 20%;
  }
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    .svg-icon {
      display: none;
    }
    &:hover {
      background: transparent;
    }
    width: 20%;
    background: transparent;
    height: calc(100% - 165px);
    border-radius: 0;
  }
}

#prev-slide {
  top: calc(50% - 16px);
  left: -75px;

  .svg-icon {
    transform: rotate(180deg);
  }
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    left: 0;
    top: 65px;
  }
  @media (max-width: 720px) {
    left: 0;
    top: 65px;
  }
}

#next-slide {
  top: calc(50% - 16px);
  right: -75px;
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    right: 0;
    top: 65px;
  }
  @media (max-width: 720px) {
    right: 0;
    top: 65px;
  }
}

body.mobile #prev-slide,
body.mobile #next-slide {
  display: none;
}

.central-area {
  position: absolute;
  height: calc(100% - 125px);
  width: 100%;
  left: 0;
  top: 30px;
  z-index: 32;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    //width: calc(100% - 90px);

    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - 140px);
  }
  @media (max-width: 820px) {
    width: calc(100% - 40%);

  }
  @media (max-width: 720px) {
    //width: calc(100% - 90px);
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 576px) {
    //height: calc(100% - 140px);
  }
}

.central-area .button {
  width: 48px;
  height: 48px;
  display: block;
  position: absolute;
  top: calc(50% - 24px - 2.5vh);
  left: calc(50% - 24px);
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease;
}

@media (min-width: 800px) {
  .central-area .button {
    top: calc(50% - 24px);
  }
}

.central-area:hover .button {
  opacity: 0.5;
}

.central-area .button svg {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.central-area .button svg path {
  display: none;
}

.central-area[data-state="paused"] .button svg #path_play {
  display: block;
}

.central-area[data-state="playing"] .button svg #path_pause {
  display: block;
}

.svg-icon--pause {
  height: 11px !important;
}

.svg-icon--volume-on {
  height: 14px !important;
}

body.menu-open .central-area .button {
  opacity: 0;
}

.progress-bars {
  position: absolute;
  padding: 20px 33px 0 33px;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  display: flex;
  @media (max-width: 576px) {
    padding: 20px 20px 0 20px;
  }
}

.progress-bars::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
}

.progress-bars[data-count="1"] .bar {
  width: calc(100% - 6px);
}

.progress-bars[data-count="2"] .bar {
  width: calc(100% / 2 - 6px);
}

.progress-bars[data-count="3"] .bar {
  width: calc(100% / 3 - 6px);
}

.progress-bars[data-count="4"] .bar {
  width: calc(100% / 4 - 6px);
}

.progress-bars[data-count="5"] .bar {
  width: calc(100% / 5 - 6px);
}

.progress-bars[data-count="6"] .bar {
  width: calc(100% / 6 - 6px);
}

.progress-bars[data-count="7"] .bar {
  width: calc(100% / 7 - 6px);
}

.progress-bars[data-count="8"] .bar {
  width: calc(100% / 8 - 6px);
}

.progress-bars[data-count="9"] .bar {
  width: calc(100% / 9 - 6px);
}

.progress-bars[data-count="10"] .bar {
  width: calc(100% / 10 - 6px);
}

.progress-bars[data-count="11"] .bar {
  width: calc(100% / 11 - 6px);
}

.progress-bars[data-count="12"] .bar {
  width: calc(100% / 12 - 6px);
}

.progress-bars[data-count="13"] .bar {
  width: calc(100% / 13 - 6px);
}

.progress-bars[data-count="14"] .bar {
  width: calc(100% / 14 - 6px);
}

.progress-bars[data-count="15"] .bar {
  width: calc(100% / 15 - 6px);
}

.progress-bars[data-count="16"] .bar {
  width: calc(100% / 16 - 6px);
}

.progress-bars[data-count="17"] .bar {
  width: calc(100% / 17 - 6px);
}

.progress-bars[data-count="18"] .bar {
  width: calc(100% / 18 - 6px);
}

.progress-bars[data-count="19"] .bar {
  width: calc(100% / 19 - 6px);
}

.progress-bars[data-count="20"] .bar {
  width: calc(100% / 20 - 6px);
}

.bar {
  display: block;
  position: relative;
  border-radius: 3px;
  height: 3px;
  margin: 0 3px;
  //cursor: pointer;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.4);
}

.bar span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  will-change: auto;
  transform: translateX(-101%) rotate(0deg);
}

.bar.seen span {
  transform: translateX(0%) rotate(0deg);
}

.bar.animate span {
  animation: bar 0s 0s linear both;
}

body.paused .bar.animate span,
body.menu-open .bar.animate span {
  animation-play-state: paused;
}

.logo {
  position: absolute;
  width: 75px;
  height: 75px;
  display: block;
  top: 24px;
  right: 8px;
  z-index: 2;
}

@media (min-width: 640px) {
  .logo {
    width: 9vh;
    height: 9vh;
    right: 12px;
    max-width: 100px;
    max-height: 100px;
  }
}

@media (min-width: 1024px) {
  .logo {
    right: 15px;
  }
}

.logo svg {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
}

.context-menu-container {
  position: absolute;
  display: block;
  width: 100%;
  height: 50px;
  bottom: 15px;
  right: 15px;
  z-index: 3;
}

.context-menu-container > .button {
  display: block;
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 50px;
  height: 50px;
}

@media (min-width: 640px) {
  .context-menu-container > .button {
    right: 5px;
  }
}

@media (min-width: 768px) {
  .context-menu-container > .button {
    right: 10px;
  }
}

@media (min-width: 1024px) {
  .context-menu-container > .button {
    right: 15px;
  }
}

.context-menu-container > .button svg {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.context-menu-container.active > .button {
  display: none;
}

.context-menu-container .context-menu {
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  height: auto;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  will-change: auto;
  transform: scale(1.05) rotate(0deg);
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
  transform 0.25s ease-in-out;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5), 0 0 30px rgba(0, 0, 0, 0.5) inset;
}

.context-menu-container.active .context-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1) rotate(0deg);
}

.context-menu-container .context-menu .link {
  display: block;
  background-color: #fff;
  width: 100%;
  padding: 18px;
  text-align: center;
  position: relative;
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #eee;
  font-weight: bold;
}

.context-menu-container .context-menu .link:last-child {
  margin-top: 10px;
}

//.toast-notification {
//  position: fixed;
//  display: inline-block;
//  text-align: center;
//  top: 3rem;
//  left: 50%;
//  color: #333;
//  border-radius: 0.25rem;
//  padding: 1rem 2rem;
//  margin: 0 auto;
//  font-size: 0.875rem;
//  font-weight: bold;
//  pointer-events: none;
//  z-index: 99;
//  will-change: auto;
//  opacity: 0;
//  transform: translate3d(-50%, 0.5rem, 0);
//  max-width: 300px;
//  width: 100%;
//  transition: opacity 0.35s ease-in-out, visibility 0.35s ease-in-out,
//  transform 0.35s ease-in-out;
//
//  &.success {
//    background-color: #1bd861;
//  }
//
//  &.error {
//    background-color: #eb5555;
//  }
//
//  &.info {
//    background-color: #0096ff;
//  }
//
//  &.active {
//    opacity: 1;
//    transform: translate3d(-50%, 0, 0);
//  }
//}

@keyframes bar {
  from {
    transform: translateX(-101%) rotate(0deg);
  }
  to {
    transform: translateX(0%) rotate(0deg);
  }
}

.stories-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 35px;
  right: 0;
  width: 100%;
  padding: 0 36px;

  &--end {
    z-index: 40;
    justify-content: flex-end;
  }

  @media (max-width: 576px) {
    padding: 0 22px;
  }

  &__time {
    position: absolute;
    top: 40px;
    left: 36px;
    font-weight: 700;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    @media (max-width: 720px) {
      left: 22px;
    }
  }

  &__play {
    width: 14px;
    height: 14px;
  }

  &__volume {
    width: 17px;
    height: 15px;
    cursor: pointer;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 17px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.daily-stories-close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: -37px;
  top: 0;
  z-index: 45;

  .svg-icon {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 820px)
              and (min-width: 320px)
              and (orientation:landscape) {
    width: 15px;
    height: 15px;
    left: 50%;
    transform: translateX(-50%);
    top: 38px;
    right: 24px;
  }
  @media (max-width: 720px) {
    width: 15px;
    height: 15px;
    //right: 40px;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
    top: 38px;
  }
  @media (max-width: 576px) {
    right: 24px;
  }
}

</style>
