import axios from "axios";

export const storiesModule = {
    state: () => ({
        stories: [],
        slides: [],
    }),
    mutations: {
        setSlides(state, slides) {
            state.slides = slides
        },
        clearSlides(state) {
            state.slides = []
        },
        setStories(state, story) {
            state.stories = story
        }
    },
    actions: {
        async fetchStories({commit}) {
            try {
                const response = await axios.get('/api/v1/stories');
                commit('setStories', response.data)
            } catch (e) {
                console.log(e)
            }
        }
    },
    namespaced: true,
}
