<template>
  <div class="filters-dates">
    <div
        ref="date"
        class="date"
        v-for="date in dates"
        :key="date.key"
    >
      <input
          type="checkbox"
          name="date"
          class="date__input"
          :disabled="date.disabled"
          @change="fetchEvents(date.date)"
          :checked="date.date == currentDate.date"
          @click="setCurrentDate(date.date)"
      />
      <div class="date__date">
        <div class="date__date-name">{{ date.week }}</div>
        <div class="date__date-number">{{ date.day }} {{ date.month }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "Dates",
  data() {
    return {
      selectedDate: this.today,
    }
  },
  computed: {
    ...mapState(['dates', 'today', 'currentDate', 'verifiedDates']),
  },
  created() {
    const windowData = Object.fromEntries(new URL(window.location).searchParams.entries())

    if (windowData.date) {
      this.selectedDate = windowData.date;
      window.history.pushState(null, document.title, `${'?date='+this.selectedDate}`);
    } else {
      window.history.pushState(null, document.title, `${window.location.pathname}`);
    }
  },
  methods: {
    ...mapActions(["getDates", "fetchEvents"]),
    setCurrentDate(date) {
      this.selectedDate = date;
      window.history.pushState(null, document.title, `${window.location.pathname}?date=${this.selectedDate}`);

      const el = document.querySelector('.festivals')
      el.scrollIntoView({
        behavior: "smooth"
      });
    }
  },
  mounted() {
    this.getDates(this.selectedDate);
  },
};
</script>

<style lang="scss">
.filters-dates {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-gap: 16px;
  width: 100%;
  @media (max-width: 1450px) {
    grid-gap: 12px;
  }
  @media (max-width: 1300px) {
    grid-gap: 8px;
  }
  @media (max-width: 1200px) {
    width: 1160px;
    flex-shrink: 0;
  }
  @media (max-width: 576px) {
    width: 791px;
    grid-gap: 7px;
  }

}

.date {
  position: relative;

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    &:hover + .date__date {
      border-color: $purple;
      color: $purple;
      background: rgba(168, 92, 233, 0.05);
    }

    &:active + .date__date {
      background: rgba(168, 92, 233, 0.18);
    }

    &:disabled {
      pointer-events: none;

      + .date__date {
        border-color: rgba(0, 0, 0, 0.3);
        color: #949494;
      }
    }

    &:checked {
      pointer-events: none;
      + .date__date {
        border-color: $purple;
        background: $purple;
        color: #fff;
      }

    }
  }

  &__date {
    color: #000;
    min-height: 80px;
    padding: 6px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    @media (max-width: 576px) {
      min-height: 48px;
    }
  }

  &__date-name {
    font-size: 12px;
    line-height: 32px;
    @media (max-width: 576px) {
      font-size: 11px;
      line-height: 20px;
    }
  }

  &__date-number {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    @media (max-width: 576px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
}
</style>
