<template>
  <GMapMap
      :center="coodinatesCenter"
      :zoom="15"
      map-type-id="terrain"
      style="width: 100%; height: 100%; z-index: 10;"
  >
    <GMapMarker
        :key="index"
        v-for="(m, index) in coordinates"
        :position="m.position"
        :clickable="true"
        @click="center=m.position"
    >
      <GMapInfoWindow
          :opened="true"
      >
        <div class="name">{{m.position.name}}</div>
        <div>{{m.position.address}}</div>
      </GMapInfoWindow>
    </GMapMarker>
  </GMapMap>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'GoogleMapLoad',
  computed: {
    ...mapState(["coordinates", "coodinatesCenter"])
  },
}
</script>

<style lang="scss">
.name {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: 1200px) {
  .gm-control-active {
    display: none;
  }
}
</style>
