<template>
  <div class="select" :class="{ active: isActive }">
    <div ref="selected" class="select__box" @click="isActive = !isActive">
      <p class="select__title">
        {{ selected }}
      </p>
      <svg-icon name="chevron" class="select__arrow" />
    </div>
    <div class="select__dropdown" ref="dropdown" @click.stop>
      <slot></slot>
      <button
        @click="fetchEvents($store.state.currentDate.date), (isActive = false)"
        class="button select__btn"
      >
        Применить
        <svg-icon name="arrow" class="button__arrow" />
      </button>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import { mapActions } from "vuex";

export default {
  name: "Select",
  components: { SvgIcon },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    ...mapActions(["fetchEvents"])
  },
  mounted() {
    document.addEventListener("click", e => {
      const target = e.target;
      const dropdown = this.$refs.dropdown;
      const selected = this.$refs.selected;

      if (target !== dropdown && target !== selected) {
        this.isActive = false;
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", e => {
      const target = e.target;
      const dropdown = this.$refs.dropdown;
      const selected = this.$refs.selected;

      if (target !== dropdown && target !== selected) {
        this.isActive = false;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.select {
  display: block;
  position: relative;
  z-index: 21;

  &.active {
    .select__dropdown {
      visibility: visible;
      opacity: 1;
      @media (max-width: 576px) {
        display: block;
      }
    }

    .select__arrow {
      transform: rotate(180deg);
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #000;
    pointer-events: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
    min-height: 25px;
    &:hover {
      color: #a85ce9;
    }
    &:active {
      color: #7239a3;
    }

    @media (max-width: 768px) {
      line-height: 24px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__dropdown {
    padding: 38px 40px 31px 40px;
    position: absolute;
    top: calc(100% + 18px);
    overflow-y: auto;
    left: -18px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 70px rgba(168, 92, 233, 0.28);
    width: 632px;
    visibility: hidden;
    opacity: 0;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    @media (max-width: 1300px) {
      width: calc(100vw - 40px);
      left: 0;
      top: calc(100% - 1px);
    }
    @media (max-width: 768px) {
      width: calc(100vw - 30px);
    }
    @media (max-width: 576px) {
      position: static;
      width: 100%;
      border: none;
      box-shadow: none;
      padding: 0;
      opacity: 1;
      visibility: visible;
      display: none;
      margin-top: 10px;
    }
  }

  &__box {
    display: inline-flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    cursor: pointer;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: -20px;
    &:hover {
      .select__title {
        color: $purple;
      }
      .select__arrow {
        fill: $purple;
      }
    }
    &:active {
      .select__title {
        color: $purple;
      }
      .select__arrow {
        fill: $purple;
      }
    }
    @media (max-width: 576px) {
      width: 100%;
      display: flex;
    }
  }

  &__arrow {
    width: 15px;
    height: 13px;
    margin-left: 13px;
    flex-shrink: 0;
    pointer-events: none;
    &:hover {
      color: #a85ce9;
    }
    &:active {
      color: #7239a3;
    }
  }

  &__btn {
    margin-top: 27px;
    max-width: 236px;
    justify-content: space-between;
    @media (max-width: 576px) {
      max-width: 100%;
      display: none;
    }
  }
}
</style>
