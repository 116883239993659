<template>
  <footer ref="footer" class="footer">
    <div class="footer-fake"></div>
    <div class="footer__box">
      <img src="@/assets/images/punchline-white-label.svg" alt="punchline" class="footer__label">
    </div>
    <div class="container">
      <img src="@/assets/images/footer-center.png" alt="illustration" class="footer__ill">

      <div class="footer__inner">
        <div class="footer__block">
          <a href="/uploads/policy.pdf" target="_blank" class="footer__link--personal">Политика обработки персональных данных</a>
          <a href="mailto:info@punchlinefest.ru" class="footer__link">info@punchlinefest.ru</a>

          <div class="footer__text">
            Сделано в
            <img src="@/assets/images/favicon.png" alt="favicon" class="footer__icon">
            <a href="https://echelon960.ru/" target="_blank" class="footer__link footer__link--small">echelon960.ru</a>
          </div>
          <div class="footer__text">
            © punchlinefest.ru, 2017 – 2024
          </div>
        </div>
      </div>
    </div>
  </footer>
  <button
    @click="scrollTop"
    v-if="visible"
    class="btn-top">
    <div class="btn-top__circle">
      <svg-icon name="arrow" class="btn-top__icon"/>
    </div>
    <div class="btn-top__text">наверх</div>
  </button>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "Footer",
  components: {SvgIcon},
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    scrollTop: function () {
      const el = document.querySelector('.festivals')
      el.scrollIntoView({
        behavior: "smooth"
      });
    },
    scrollListener: function () {
      this.visible = window.scrollY > 250;
      const footerHeight = this.$refs.footer.offsetHeight;
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - (footerHeight / 1.5)) {
        this.visible = false;
      }
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeUnmount: function () {
    window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

<style lang="scss" scoped>
.footer {
  min-height: 516px;
  overflow: hidden;
  background: $pink;
  padding: 37px 0 120px 0;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    min-height: 225px;
  }
  @media (max-width: 576px) {
    min-height: 150px;
    padding-bottom: 0;
  }

  &::after {
    content: '';
    width: 860px;
    height: 330px;
    background: url("~@/assets/images/footer-left.png") center /contain no-repeat;
    position: absolute;
    left: -240px;
    bottom: -5%;
    z-index: -1;
    @media (max-width: 992px) {
      height: 230px;
      width: 700px;
    }
    @media (max-width: 768px) {
      width: 510px;
      height: 165px;
      bottom: -18%;
    }
    @media (max-width: 520px) {
      display: none;
    }
  }

  &::before {
    content: '';
    width: 860px;
    height: 330px;
    background: url("~@/assets/images/icons/footer-dots.svg") center /contain no-repeat;
    position: absolute;
    left: 16%;
    top: -50%;
    z-index: -1;
    @media (max-width: 992px) {
      height: 230px;
      width: 400px;
      left: 25%;
      top: -28%;
      transform: translateX(-25%);
    }
    @media (max-width: 768px) {
      height: 165px;
      width: 250px;
      top: -36%;
    }
    @media (max-width: 520px) {
      display: none;
    }
  }

  &-fake {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;

    &::before {
      content: '';
      width: 860px;
      height: 330px;
      background: url("~@/assets/images/footer-right.png") center /contain no-repeat;
      position: absolute;
      right: -175px;
      bottom: 35px;
      z-index: -1;
      @media (max-width: 1200px) {
        height: 230px;
        width: 625px;
      }
      @media (max-width: 992px) {
        height: 210px;
        width: 600px;
        bottom: 0;
      }
      @media (max-width: 768px) {
        background: url("~@/assets/images/footer-right-tablet.png") center /contain no-repeat;
        height: 100px;
        width: 400px;
        right: -155px;
        bottom: 30px;
      }
      @media (max-width: 520px) {
        display: none;
      }
    }
    @media (max-width: 576px) {
      display: none;
    }
  }

  &__label {
    margin-right: 93px;
    height: 133px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -5% 98px -2.5%;
    width: calc(100% + 15%);
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    margin-right: 22%;
    @media (max-width: 1200px) {
      margin-right: 15%;
    }
    @media (max-width: 992px) {
      margin-right: 15%;
    }
    @media (max-width: 768px) {
      /*height: 115px;*/
    }
    @media (max-width: 520px) {
      justify-content: flex-start;
      &:after {
        content: '';
        width: 45px;
        height: 44px;
        background: url("~@/assets/images/footer-right-mobile.png") center /contain no-repeat;
        position: absolute;
        right: 0;
        top: -15px;
        z-index: -1;
      }
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: url("~@/assets/images/footer-dot-mob.svg") center /contain no-repeat;
        position: absolute;
        right: 0;
        bottom: 35%;
        z-index: -1;
      }
    }
  }
  &__block {
    @media (max-width: 768px) {
      position: absolute;
      top: 45%;
    }
    @media (max-width: 520px) {
      position: static;
    }
  }

  &__ill {
    position: absolute;
    max-width: 228px;
    bottom: 0;
    left: 33%;
    margin-bottom: 40px;
    z-index: 1;
    @media (max-width: 992px) {
      width: 15vw;
    }
    @media (max-width: 768px) {
      left: 25%;
      width: 150px;
      margin-bottom: 0;
    }
    @media (max-width: 576px) {
      width: 125px;
      left: 20%;
    }
    @media (max-width: 520px) {
      display: none;
    }
  }

  &__link {
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 31px;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: rgba(#fff, 0.7);
    }

    @media (max-width: 768px) {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
    }
    @media (max-width: 576px) {
      font-size: 18px;
    }

    &--small {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 32px;
    color: #fff;
    display: flex;
    align-items: center;

    &:first-of-type {
      margin-top: 18px;
      @media (max-width: 520px) {
        margin-top: 0;
      }
    }
  }

  &__icon {
    display: inline;
    margin: 0 13px 0 10px;

  }
}


a.footer__link--personal {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #fff;
  width: 235px;
  @media (max-width: 520px) {
    /*font-size: 13px;*/
    margin-bottom: 15px;
  }
}
a.footer__link--personal:hover{
  text-decoration: underline;
}
</style>
