<template>
  <FestivalsDynamic/>
</template>

<script>
import FestivalsDynamic from "@/components/FestivalsDynamic";

export default {
  name: "package",
  components: {FestivalsDynamic},
}
</script>

<style scoped>

</style>