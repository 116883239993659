<template>
  <label class="checkbox">
    <input v-model="inputValue" type="checkbox" class="checkbox__input" />
    <div class="checkbox__inner">
      <div class="checkbox__square"></div>
      <div class="checkbox__box">
        <div class="checkbox__wrapper checkbox__wrapper--mb">
          <div class="checkbox__text">
            {{ name }}
          </div>
        </div>

        <div class="checkbox__desc">
          {{ description }}
        </div>
        <div class="checkbox__addresses">
          <div
            class="address checkbox__address"
            v-for="metro in metro"
            :key="metro.name"
          >
            <div
              :style="{ background: `${metro.color}` }"
              class="address__circle"
            ></div>
            <div class="address__text" v-if="metro.name">
              {{ metro.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String
    },
    description: {
      type: String
    },
    metro: {
      type: Array
    },
    metroColor: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", {
          id: this.id,
          value,
          name: this.name
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-block;
  position: relative;
  z-index: 3;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
    opacity: 0;

    &:hover {
      + .checkbox__inner {
        .checkbox__square {
          background: rgba(58, 100, 254, 0.05);
          border: 1px solid $purple;
        }
      }
    }

    &:active {
      + .checkbox__inner {
        .checkbox__square {
          background: rgba(58, 100, 254, 0.18);
          border: 1px solid $purple;
        }
      }
    }

    &:checked {
      + .checkbox__inner {
        .checkbox__square {
          background: $purple;
          border-color: $purple;

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
  }

  &__square {
    width: 26px;
    height: 26px;
    margin: 0 17px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    position: relative;
    flex-shrink: 0;

    &::before {
      content: "";
      width: 10px;
      height: 8px;
      background: url("~@/assets/images/icons/checked.svg") center / contain
        no-repeat;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  &__text {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: flex-start;
  }

  &__addresses {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__address {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__desc {
    font-size: 12px;
    line-height: 16px;
    color: #000;
  }

  &__hint {
    margin-left: 5px;
    display: inline;
    @media (max-width: 576px) {
      display: block;
      margin-left: auto;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    line-height: 20px;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__box {
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
</style>
