<template>
  <button
      class="btn-filters"
      v-if="visible"
      :style="{bottom: bottomPadding + '%'}"
      @click.prevent="isFiltersVisible = !isFiltersVisible"
  >
    <svg-icon class="btn-filters__icon" name="filter"/>
  </button>

  <div ref="filters" class="filters" :class="{ active: isFiltersVisible }">
    <div class="filters__overhead">
      <button class="filters__back" @click.prevent="isFiltersVisible = false">
        <svg-icon name="arrow" class="filters__back-icon"/>
      </button>
      <button
          @click.prevent="resetFilters()"
          class="filters__reset filters__reset--mob">
        Сбросить фильтр
      </button>
    </div>
    <div class="filters__grid">
      <div class="filters__box">
        <div class="filters__label">Площадка</div>
        <SelectPlaces/>
      </div>
      <div class="filters__box">
        <div class="filters__label">Тип</div>
        <SelectTypes/>
      </div>
      <div class="filters__box">
        <div class="filters__label">Вход</div>
        <SelectVisits/>
      </div>
      <div class="filters__box filters__box--hidden">
        <Checkbox
            :value="$store.state.hiddenEvents"
            @change.self="hideEmptyEvents"
            @click="(isFiltersVisible = false)"
            :description="hideText"/>
      </div>
    </div>
    <div class="filters__wrapper">
      <Dates/>
      <button class="filters__reset" @click.prevent="resetFilters()">
        <svg-icon name="filter" class="filters__icon"/>
        Сбросить <br/>
        фильтр
      </button>
    </div>
    <div class="filters__panel">
      <Checkbox
          class="checkbox-center"
          :value="$store.state.hiddenEvents"
          @change.self="hideEmptyEvents"
          :description="hideText"/>
      <button class="filters__reset-text"
              @click.prevent="resetFilters()"
      >Сбросить фильтр
      </button>
    </div>
    <button @click="fetchEvents($store.state.currentDate.date), (isFiltersVisible = false)" class="button filters__btn">
<!--    <button @click="fetchEvents($store.state.currentDate.date), (isFiltersVisible = false)" class="button filters__btn">-->
      Применить
      <svg-icon name="arrow" class="button__arrow"/>
    </button>
  </div>

  <div ref="filtersSticky" class="filters-sticky">
    <div class="container">
      <div class="filters-sticky__inner">
        <div class="filters-sticky__grid">
          <div class="filters-sticky__block">
            <SelectPlaces
            @click="isDatesVisible = false"
            />
          </div>

          <div class="filters-sticky__block">
            <SelectTypes
            @click="isDatesVisible = false"
            />
          </div>

          <div class="filters-sticky__block">
            <SelectVisits
            @click="isDatesVisible = false"
            />
          </div>

          <div class="filters-sticky__block">
            <div
                class="filters-sticky__dates"
                @click="isDatesVisible = !isDatesVisible"
                :class="{ active: isDatesVisible }"
            >
              <div class="filters-sticky__label">{{ currentDate.day }} {{ currentDate.month }}, {{
                  currentDate.week
                }}
              </div>
              <svg-icon name="chevron" class="filters-sticky__arrow"></svg-icon>
            </div>
            <button
                @click.prevent="resetFilters()"
                class="filters-sticky__reset">Сбросить фильтр
            </button>
          </div>
        </div>

        <div class="dates-sticky-wrapper">
          <Dates class="filters-sticky-dates" v-show="isDatesVisible"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import Checkbox from "@/components/Checkbox";
import SelectTypes from "@/components/Filters/SelectTypes";
import SelectPlaces from "@/components/Filters/SelectPlaces";
import SelectVisits from "@/components/Filters/SelectVisits";
import Dates from "@/components/Dates";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Filters",
  components: {
    SelectVisits,
    SelectPlaces,
    SvgIcon,
    Checkbox,
    SelectTypes,
    Dates
  },
  data() {
    return {
      isFiltersVisible: false,
      hideText: "Скрыть мероприятия, на которые закончились билеты",
      visible: true,
      isDatesVisible: false,
      bottomPadding: null,
    };
  },
  computed: {
    ...mapState(["currentDate"]),
  },
  methods: {
    ...mapActions(["fetchEvents"]),
    ...mapMutations(["hideEmptyEvents", "resetFilters"]),
    handleScroll() {
      let filters = this.$refs.filters;
      let filtersSticky = this.$refs.filtersSticky;
      if (filters.getBoundingClientRect().top >= -filters.offsetHeight) {
        filters.classList.remove("hidden");
        filtersSticky.classList.remove("active");
      } else {
        filters.classList.add("hidden");
        filtersSticky.classList.add("active");
      }
    },
    scrollListener: function () {
      const footerHeight = document.querySelector(".footer").offsetHeight;
      if(document.body.offsetHeight <= window.innerHeight + window.scrollY) {
        this.visible = true;
        this.bottomPadding = 18
      } else {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - footerHeight / 1.5
        ) {
          this.visible = false;
        } else {
          this.visible = true;
          this.bottomPadding = 5
        }
      }
    }
  },
  watch: {
    isFiltersVisible() {
      document.querySelector("body").style.overflow = this.isFiltersVisible
          ? "hidden"
          : null;
    },
  },
  mounted() {
    if(document.body.offsetHeight <= window.innerHeight + window.scrollY) {
      this.bottomPadding = 18
    }
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.scrollListener);

  }
};
</script>

<style lang="scss">
.filters {
  display: none;
  background: #fff;
  z-index: 25;
  padding-bottom: 16px;
  &--reset {
    z-index: initial;
    padding-bottom: 0;
  }

  &.active {
    z-index: 42;
  }

  @media (max-width: 576px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff;
    z-index: 21;
    padding: 15px 15px 140px 15px;
    display: none;
    flex-direction: column;
    overflow-x: hidden;

    &.active {
      display: flex;
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 32px;
    @media (max-width: 1300px) {
      position: relative;
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
      margin-bottom: 14px;
    }
    @media (max-width: 576px) {
      //display: flex;
      //flex-direction: column;
      margin-bottom: 0;
      display: block;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px 17px 18px 17px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-left: none;

    &:first-child {
      padding-left: 0;

      .select__dropdown {
        left: 0;
      }
    }

    &:last-child {
      border-right: none;
      @media (max-width: 576px) {
        border-bottom: none;
        margin-bottom: 24px;
        padding-bottom: 0;
        padding-top: 24px;
      }
    }

    &--hidden {
      @media (max-width: 1200px) {
        display: none;
      }
      @media (max-width: 576px) {
        //display: block;
      }
    }

    @media (max-width: 1300px) {
      .select {
        position: static;
      }
    }
    @media (max-width: 1200px) {
      &:nth-child(3) {
        border-right: none;
      }
    }
    @media (max-width: 768px) {
      padding: 10px 15px 17px 15px;
    }
    @media (max-width: 576px) {
      border-right: 0;
      border-top: 0;
      padding: 15px 0;
      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
  }

  &__label {
    font-size: 12px;
    line-height: 32px;
    color: #000;
    @media (max-width: 576px) {
      line-height: 16px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
      overflow-x: auto;
      margin: 0 -20px;
      padding: 0 20px;

      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
    @media (max-width: 576px) {
      order: -1;
      flex-shrink: 0;
      overflow-x: auto;
      margin-bottom: 25px;
    }
  }

  &__icon {
    width: 18px;
    height: 16px;
    stroke: $purple;
    fill: $purple;
    margin-bottom: 13px;
  }

  &__reset {
    font-size: 12px;
    line-height: 12px;
    color: $purple;
    margin-left: 28px;
    @media (max-width: 1450px) {
      margin-left: 20px;
    }

    &:hover {
      color: #f433b5;

      .filters__icon {
        fill: #f433b5;
        stroke: #f433b5;
      }
    }

    &:active {
      color: #d01794;

      .filters__icon {
        fill: #d01794;
        stroke: #d01794;
      }
    }

    &:disabled {
      .filters__icon {
        fill: #949494;
        stroke: #949494;
      }

      color: #949494;
    }

    @media (max-width: 1200px) {
      display: none;
    }

    &--mob {
      @media (max-width: 576px) {
        display: block;
      }
    }
  }

  &__overhead {
    align-items: center;
    justify-content: space-between;
    display: none;
    margin-bottom: 35px;
    order: -1;
    @media (max-width: 576px) {
      display: flex;
      margin-bottom: 30px;
    }
  }

  &__back-icon {
    width: 20px;
    transform: rotate(180deg);
  }

  &__hide {
    margin-top: 20px;
    display: none;
    @media (max-width: 576px) {
      display: block;
      margin-bottom: 40px;
    }
  }

  &__btn {
    display: none !important;
    justify-content: space-between !important;
    margin-top: auto;
    @media (max-width: 576px) {
      display: flex !important;
      margin-top: 30px;
    }
  }

  &__hint {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
  }

  &__hint-img {
    fill: $pink;
    width: 20px;
    margin-right: 19px;
    display: block;
  }

  &__hint-text {
    font-size: 12px;
    line-height: 16px;
    color: #2c3282;
    width: 100%;
    max-width: 234px;
  }

  &__panel {
    display: none;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1200px) {
      display: flex;
      margin-top: 27px;
    }
    @media (max-width: 576px) {
      display: none;
    }
  }

  &__reset-text {
    font-size: 12px;
    line-height: 32px;
    color: $purple;
  }
}

.filters-sticky {
  z-index: 21;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 70px rgba(168, 92, 233, 0.28);
  &.active {
    position: fixed;
    opacity: 1;
    visibility: visible;
  }


  .filters__dates {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    padding: 16px 0;
    @media (max-width: 768px) {
      padding: 12px 0;
    }
  }

  .select__box {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .select__title {
    font-size: 14px;
    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .select__arrow {
    margin-right: 10px;
  }

  &__reset {
    font-size: 12px;
    line-height: 12px;
    color: #a85ce9;
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .filters__date {
    min-height: 64px;
  }

  .filters__date-name {
    line-height: 26px;
  }

  .filters__date-number {
    font-size: 14px;
    line-height: 28px;
  }

  .select__dropdown {
    left: -1px;
    top: calc(100% + 7px);
  }



  &__inner {
    //height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__grid {
    //height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 1300px) {
      position: relative;
    }
  }

  &__block {
    padding: 7px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid rgba(0, 0, 0, 0.3);

    .select__box {
      padding-left: 16px;
      @media (max-width: 768px) {
        padding: 0 12px;
      }
    }

    &:last-child {
      border-right: 0;
    }

    &:first-child {
      .select__box {
        padding-left: 0;
      }
    }
  }

  &__dates {
    padding-left: 16px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &.active {
      .filters-sticky__arrow {
        transform: rotate(180deg);
      }
    }

    &:hover {
      .filters-sticky__label {
        color: #a85ce9;
      }

      .filters-sticky__arrow {
        fill: #a85ce9;
      }
    }

    &:active {
      .filters-sticky__label {
        color: #7239a3;
      }

      .filters-sticky__arrow {
        fill: #7239a3;
      }
    }
  }

  &__label {
    font-size: 14px;
    line-height: 32px;
    font-weight: 700;
    color: #000;
    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 20px;
    }
  }

  &__arrow {
    width: 15px;
    height: 13px;
    fill: #000;
    margin-left: 15px;
  }

  @media (max-width: 1300px) {
    .select {
      position: static;
    }
    .select__dropdown {
      top: 100%;
    }
  }
  @media (max-width: 1200px) {
    padding-bottom: 0;
  }
  @media (max-width: 992px) {
    display: none;
  }
}

.filters-sticky-dates {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 16px 0;
  @media (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 1200px) {
    padding: 16px 0;
  }
}

.dates-sticky-wrapper {
  @media (max-width: 1200px) {
    overflow-x: auto;
    //margin: 0 -20px;
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;

    }
  }
}

</style>
