<template>
  <div class="main-wrapper">
    <div class="main">
      <div class="container">
        <div class="main__box">

        </div>
        <div class="main__inner">
          <p class="main__title">15 — 25 августа</p>

          <img src="@/assets/images/main_title_2024.svg" alt="punchline" class="main__img">

<!--          <img src="@/assets/images/hlep.svg" alt="help" class="main__text">

          <img class="main__link" src="@/assets/images/vk_link.svg" alt="help">-->
        </div>
      </div>

      <img src="@/assets/images/image1.png" alt="help" class="main__image main__image-1">
      <img src="@/assets/images/image2.png" alt="help" class="main__image main__image-2">
      <img src="@/assets/images/image3.png" alt="help" class="main__image main__image-3">
      <img src="@/assets/images/image4.png" alt="help" class="main__image main__image-4">
      <img src="@/assets/images/image5.png" alt="help" class="main__image main__image-5">
      <img src="@/assets/images/image6.png" alt="help" class="main__image main__image-6">
      <div class="main__image main__image-flex main__image-7">
        <img src="@/assets/images/header-dots.svg" alt="help" class="main__image-7">
        <img src="@/assets/images/header-dots.svg" alt="help" class="main__image-7 main__image-7-mob">
      </div>
      <div class="main__image main__image-flex main__image-8">
        <img src="@/assets/images/header-dots.svg" alt="help" class="main__image-7">
        <img src="@/assets/images/header-dots.svg" alt="help" class="main__image-7">
      </div>
    </div>
    <Videos/>

  </div>

</template>

<script>
import Videos from "@/components/Videos";

export default {
  name: "Main",
  components: {
    Videos,
  },
}
</script>

<style lang="scss" scoped>
  .main {
    background: $pink;
    min-height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 25;
    @media (max-width: 1450px) {
      min-height: 415px;
    }
    @media (max-width: 992px) {
      min-height: 355px;
    }
    @media (max-width: 768px) {
      min-height: 240px;
    }
    @media (max-width: 630px) {
      min-height: 205px;
      justify-content: flex-start;
    }
    &__inner {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: fit-content;
      position: relative;
      padding-right: 49%;
      margin-left: auto;
      z-index: 20;
      @media (max-width: 1449px) {
        margin-left: 0;
        padding-right: 56%;
      }
      @media (max-width: 1200px) {
        padding-right: 40%;
        margin-left: auto;
      }
      @media (max-width: 992px) {
        padding-right: 30%;
      }
      @media (max-width: 768px) {
        padding-right: 35%;
      }
      @media (max-width: 630px) {
        padding-right: 0;
        justify-content: center;
        margin: 12px auto 0;
      }
    }
    &__title {
      color: #FFF;
      font-size: 45px;
      font-family: Solomon, 'sans-serif';
      font-style: normal;
      font-weight: 900;
      line-height: 100px;
      text-transform: uppercase;
      margin-bottom: 18px;
      @media (max-width: 992px) {
        margin-bottom: 0;
      }
      @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 0;
        line-height: 50px;
      }
      @media (max-width: 630px) {
        margin-bottom: 10px;
        margin-top: 5px;
        line-height: 35px;
      }
      @media (max-width: 576px) {
        margin-top: 1.5px;
      }
    }
    &__text {
      margin-top: 43px;
      margin-bottom: 18px;
      @media (max-width: 1200px) {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      @media (max-width: 630px) {
        margin-bottom: 7px;
        width: 45px;
      }
    }
    &__link {
      @media (max-width: 768px) {
        width: 15.2vw;
      }
      @media (max-width: 630px) {
        width: 73px;
      }
    }
    &__box {
      position: absolute;
      left: -35px;
      top: 0;
      z-index: -1;
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        top: 8%;
      }
      @media (max-width: 768px) {
        top: 0;
        position: static;
      }
    }
    &__img {
      width: 100%;
      max-width: 609px;
      @media (max-width: 992px) {
        width: 60vw;
      }
      @media (max-width: 768px) {
        width: 56vw;
      }
      @media (max-width: 630px) {
        max-width: 251px;
        width: 95vw;
      }
    }
    &__image {
      position: absolute;
      &-flex {
        display: flex;
        align-items: center;
      }
      &-1 {
        left: -25px;
        bottom: -30px;
        z-index: 30;
        width: 8.7vw;
        @media (max-width: 1200px) {
          left: 80px;
          width: 10vw;
        }
        @media (max-width: 991px) {
          width: 11vw;
          bottom: -20px;
        }
        @media (max-width: 630px) {
          bottom: 52px;
          left: 60px;
          width: 45px;
        }
      }
      &-2 {
        left: calc(30% - 450px);
        z-index: 30;
        width: 9vw;
        @media (max-width: 1500px) {
          left: calc(30% - 380px);
          width: 7vw;
          top: 50px;
        }
        @media (max-width: 1200px) {
          left: 0;
          top: 95px;
        }
        @media (max-width: 768px) {
          left: 0;
          top: 35px;
        }
        @media (max-width: 630px) {
          left: 15px;
          width: 31px;
          top: 25px;
        }
      }
      &-3 {
        bottom: -39%;
        left: 44%;
        width: 17vw;
        z-index: 30;
        @media (max-width: 1450px) {
          bottom: -25%;
          left: 40%;
        }
        @media (max-width: 1200px) {
          top: 0;
          right: 20%;
          left: unset;
          bottom: unset;
        }
        @media (max-width: 992px) {
          right: 10%;
          top: 20%;
        }
        @media (max-width: 768px) {
          top: 0;
          right: 10%;
          width: 22vw;
        }
        @media (max-width: 630px) {
          top: 105px;
          width: 64px;
          z-index: 20;
        }
      }
      &-4 {
        top: 45px;
        right: 500px;
        @media (max-width: 1200px) {
          display: none;
        }
      }
      &-5 {
        bottom: 44px;
        right: 250px;
        @media (max-width: 1200px) {
          display: none;
        }
      }
      &-6 {
        right: 0;
        top: -10px;
        z-index: 25;
        width: 9vw;
        pointer-events: none;
        @media (max-width: 1200px) {
          top: 50%;
          right: 50px;
        }
        @media (max-width: 768px) {
          top: 40%;
          right: 30px;
        }
        @media (max-width: 630px) {
          top: 40%;
          right: -7px;
        }
      }
      &-7 {
        margin-right: 25px;
        left: -45px;
        bottom: -70px;
        @media (max-width: 1200px) {
          &-mob {
            display: none;
          }
        }
        @media (max-width: 992px) {
          left: -70px;
          bottom: -140px;
        }
        @media (max-width: 630px) {
          left: -43px;
          bottom: 113px;
          width: 155px;
          margin-right: 0;
        }
      }
      &-8 {
        margin-right: 25px;
        right: -285px;
        top: -110px;
        @media (max-width: 1200px) {
          right: -420px;
          top: -135px;
        }
        @media (max-width: 630px) {
          right: -45px;
          top: 85px;
          width: 155px;
          margin-right: 0;
        }
      }
    }
  }
  .main-wrapper {
    position: relative;
  }
</style>
