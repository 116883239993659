<template>
  <div class="festivals" >
    <div class="container">
      <div class="title festivals__title">Приветствуем тебя</div>
<!--      <p class="error">Регистрация на фестиваль комедии Панчлайн 2024 завершена. <br />Совсем скоро здесь появится расписание😉</p>-->


      <Filters />
      <div v-if="$store.state.error">
        <div class="error">{{ $store.state.error }}</div>
      </div>
      <FestivalsList />


    </div>


  </div>
</template>

<script>
import Filters from "@/components/Filters/Filters";
import FestivalsList from "@/components/FestivalsList";
export default {
  name: "Festivals",
  components: {FestivalsList, Filters}
}
</script>

<style lang="scss" scoped>
.festivals {
  padding: 87px 0 179px 0;
  position: relative;
  @media (max-width: 1450px) {
    padding: 80px 0 170px 0;
  }
  @media (max-width: 992px) {
    padding-bottom: 150px;
  }
  @media (max-width: 768px) {
    padding-bottom: 110px;
    padding-top: 60px;
  }
  @media (max-width: 576px) {
    padding: 95px 0 80px 0;
    //padding-top: 40px;
  }
  &__title {
    margin-bottom: 41px;
    padding-right: 40px;
    position: relative;
    display: inline-block;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      display: block;
      width: 25px;
      height: 20px;
      background: url("~@/assets/images/title-logo.svg") center /contain no-repeat;
      @media (max-width: 992px) {
        display: none;
      }
    }
    @media (max-width: 992px) {
      margin-bottom: 26px;
    }
    @media (max-width: 576px) {
      display: none;
    }
  }
}

.error {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  margin-top: 50px;
  @media (max-width: 576px) {
    font-size: 18px;
  }
}
</style>
